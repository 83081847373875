import React, { useEffect, useState } from 'react';
import { Button, Rate, Card, Tooltip, Row, Col, Modal, message, Popconfirm, Space } from 'antd';
import './main.css'
import EditMovie from './editMovie';


function Movie(props) {
    let [dianYingLieBiao, setdianYingLieBiao] = useState([])
    let [count, setCount] = useState(0)

    const EditFunction = (o) => {
        let isOK = { isOK: false }
        Modal.info({
            maskClosable: true,
            centered: true,
            width: '90%',
            content: <EditMovie o={o} isOK={isOK} isLogin={props.isLogin} />,
            okText: "",
            closable: true,
            icon: null,
            okButtonProps: { disabled: true, hidden: true },
            afterClose: () => {
                if (isOK.isOK === true) {
                    DB()
                    setCount(count + 1)
                }

            }

        });
        //console.log(o)
    }
    const DB = () => {
        fetch("//happymade.cn/Blog/getdata.ashx?what=movie", { method: 'GET' }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                setdianYingLieBiao(e1)
            }
        ).catch(
            ex => {
                message.error("发生错误，原因：" + ex)
            }
        )
    }
    const Delete = (key) => {
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: key, what: "deleteMovie",ticket:props.isLogin }) }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                if (e1.result === "OK") {
                    message.success(e1.message)
                    DB()
                    setCount(count + 1)
                } else {
                    message.error(e1.message)
                }

            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }
    useEffect(DB, [])

    return <>
        <div style={{ paddingTop: 30 }}>
            <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                <h1>近期观影 {props.isLogin ? <Button size='small' onClick={() => EditFunction()} >添加电影</Button> : null}</h1>
                <Row wrap gutter={[16, 16]} >
                    {
                        dianYingLieBiao.map((o, index) => {
                            return (
                                <Col xs={24} lg={6} md={8} sm={12} key={index}>

                                    <Card
                                        onClick={() => window.open(o.url)}
                                        hoverable style={{ maxWidth: 400, padding: 5, borderColor: "#CCC" }}
                                        cover={<img style={{ maxHeight: 400, objectFit: "scale-down" }} alt="电影海报图片" src={"//happymade.cn/Blog/getdata.ashx?what=" + o.image} />}
                                    >
                                        <Card.Meta title={<span title={o.name}>{o.name}</span>} description={"观看日期：" + (new Date(o.date)).toLocaleDateString()} />
                                        <Tooltip title={"个人评分：" + o.star}>
                                            <div></div><Rate allowHalf value={o.star} disabled />
                                        </Tooltip>
                                        {props.isLogin ? (
                                            <div style={{ marginTop: 10 }} onClick={e => { e.stopPropagation() }}>
                                                <Space>
                                                    <Button size='small' onClick={() => EditFunction(o)}>编辑</Button>
                                                    <Popconfirm
                                                        title="是否要删除此条观影记录？"
                                                        onConfirm={() => Delete(o.key)}
                                                        okText="确认删除"
                                                        cancelText="取消"
                                                        okButtonProps={{ danger: true }}
                                                    >
                                                        <Button size='small' type="danger" >删除</Button>
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        ) : null}


                                    </Card>

                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    </>
}
export default Movie