import React from "react";
//import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import { Input, Button, message, Space, Popconfirm, Dropdown, Modal } from 'antd';
import { DownOutlined } from '@ant-design/icons';
export class EditKnowledge extends React.Component {
    componentDidUpdate() {
        this.init(this.props)
    }
    V = {}
    constructor(props) {
        super()
        // console.log(props.item.key)
        if (props.item === null) {
            this.state = { editor: null }
        } else {
            this.state = { editor: null, title: props.item.title, sammary: props.item.sammary, content: props.item.content, key: props.item.key }
        }
        this.init(props)
    }
    init = (props) => {
        this.V.caiDan = []
        for (let i = 0; i < props.caiDan.length; i++) {

            let o = props.caiDan[i]

            if (o.hidden === undefined) {

                let n = {}
                n.label = typeof (o.label) === "object" ? o.label[1] : o.label
                n.key = o.key
                n.children = []
                if (o.children !== undefined) {
                    for (let j = 0; j < o.children.length; j++) {
                        let c = o.children[j]
                        if (c.hidden === undefined) {
                            let n1 = {}
                            n1.key = c.key
                            n1.label = typeof (c.label) === "object" ? c.label[1] : c.label
                            n.children.push(n1)
                        }
                    }
                }
                if (n.children.length === 0) {
                    n.children = undefined
                }
                this.V.caiDan.push(n)
            }
        }




    }
    componentWillUnmount() {
        if (this.state.editor == null) return
        this.state.editor.destroy()
    }
    onSave = () => {
        //console.log(this.state)
        if (this.state.title === "" || this.state.title === undefined) {
            message.error("文章标题未设置")
            return
        } else if (this.state.content === "" || this.state.content === undefined) {
            message.error("文章正文未设置")
            return
        }
        //console.log(this.props.item)
        // let key = this.props.item.key
        let key
        if (this.props.item === undefined) {
            key = null
        } else {
            key = this.state.key
        }
        let sammary = this.props.sammary
        if (sammary === undefined) sammary = ""
        let po = { title: this.state.title, what: "postKnowledge", sammary: this.state.sammary, content: this.state.content, kind: this.props.kind, key: key, ticket: this.props.isLogin }
        //console.log(po, "111111111111111111111111111111111111111111111")
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify(po) }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                if (e1.result === "OK") {
                    message.success(e1.message)
                    //console.log(this.state.key)
                    if (this.state.key === undefined) {
                        let newKey = e1.newKey
                        this.props.content(newKey)
                    } else {
                        this.props.content(this.props.item.key)
                    }
                } else {
                    message.error(e1.message)
                }
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }

    Delete = (key) => {
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: this.state.key, ticket: this.props.isLogin, what: "deleteList" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1.message)

                if (e1.result === "OK") {
                    message.success(e1.message)
                    //console.log(this.props.item.kind)
                    this.props.changeKind({ key: this.props.item.kind })
                } else {
                    message.error(e1.message)
                }
                //console.log(e1.message)
                this.setState({})
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }



    render() {
        return (
            <>
                <div style={{ border: '1px solid #ccc', zIndex: 100 }}>
                    <Input.Search
                        addonBefore="标题：" placeholder="请输入标题"
                        onChange={d => { this.setState({ title: d.target.value }) }}
                        value={this.state.title}
                        enterButton="保存"
                        size="large"
                        onSearch={this.onSave}
                    />
                    <Input value={this.state.sammary} onChange={d => { this.setState({ sammary: d.target.value }) }} addonBefore="摘要：" placeholder="可选，请输入摘要；如为空，则自动提取正文前100字。" />
                    <Toolbar
                        editor={this.state.editor}
                        defaultConfig={{}}
                        mode="default"
                        style={{ borderBottom: '1px solid #ccc' }}
                    />
                    <Editor
                        defaultConfig={{ placeholder: '请输入内容...' }}
                        value={this.state.content}
                        onCreated={(v) => {
                            this.setState({ editor: v })
                        }}
                        onChange={editor => this.setState({ content: editor.getHtml() })}
                        mode="default"
                        style={{ height: '500px', overflowY: 'hidden' }}
                    />

                </div>
                {this.props.item === null ? null : (<Space style={{ marginTop: 15 }}>
                    <Dropdown trigger={["click"]} on
                        menu={{
                            onClick: this.changeKind,
                            items: this.V.caiDan
                        }}
                    ><Button size='small' type="primary">修改分类</Button></Dropdown>
                    <Popconfirm
                        title="是否要删除此文章？"
                        onConfirm={() => this.Delete(this.state.key)}
                        okText="确认删除"
                        cancelText="取消"
                        okButtonProps={{ danger: true }}
                    >
                        <Button size='small' type="danger" >删除</Button>

                    </Popconfirm>

                </Space>)}

            </>
        )
    }
    changeKind = (x) => {
        let n = this.props.yuanShiCaiDan.filter(i => i.key.toString() === x.key)
        Modal.confirm({
            title: "分类切换提示...",
            content: "是否分类到新的【" + (typeof (n[0].label) === "object" ? n[0].label[1] : n[0].label) + "】分类？",
            onOk: () => {
                fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ kind:x.key,key:this.state.key,ticket:this.props.isLogin,what: "changeKind" }) }).then(
                    e => {
                        return e.json()
                    }
                ).then(
                    e1 => {
                        if (e1.result === "OK") {
                            message.success(e1.message)
                            //console.log(this.props.item.kind)
                            this.props.changeKind(x)
                        } else {
                            message.error(e1.message)
                        }
                        this.setState({})
                    }
                ).catch(
                    ex => {
                        message.error("发生错误！原因：" + ex)
                    }
                )
            }
        })

    }
}