import React from "react";
import { Input, Button, Typography, message, Space, Progress, Row, Col, Alert, Tooltip } from 'antd';
import { AudioTwoTone, AudioOutlined, AudioMutedOutlined, CheckOutlined, RedoOutlined, CaretRightOutlined, PauseOutlined, BorderOutlined, CustomerServiceOutlined, SyncOutlined } from '@ant-design/icons';
import Recorder from "./Recorder";
export class Speech extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            input: ""
        }
    }
    componentWillUnmount() {
        clearInterval(this.V.t)
    }
    speechListen = (isRand = false) => {
        if (isRand === true) {
            this.setState({ input: "" })
        }
        this.V.isFirst = false
        this.V.JSON = undefined
        this.V.disabled = true
        this.setState({})
        message.info("开始处理生成语音信息……")
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ input: (isRand === true ? "" : this.state.input), ticket: this.props.isLogin, what: "speechListen" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                if (e1.result === "OK") {
                    message.success(e1.message)
                    //console.log(this.V.audio)
                    this.V.audio.src = "data:audio/wav;base64," + e1.WAV
                    this.V.input = e1.input
                    this.V.JSON = e1.JSON
                    this.V.FY = e1.FY
                    this.V.audio.load()

                    let a = []
                    let s = ""
                    //s.substring
                    let p = 0
                    for (let i = 0; i < this.V.JSON.length; i++) {
                        let w = this.V.JSON[i].Word.toLowerCase()
                        let tp = this.V.input.toLowerCase().indexOf(w, p)
                        if (tp >= 0) {
                            p = tp + w.length
                            a.push(this.V.JSON[i])
                            if (i + 1 >= this.V.JSON.length) {
                                s = this.V.input.substring(p)
                                a.push(s)
                                break;
                            }
                            w = this.V.JSON[i + 1].Word.toLowerCase()
                            tp = this.V.input.toLowerCase().indexOf(w, p)
                            s = this.V.input.substring(p, tp)
                            a.push(s)
                            p = tp
                        }
                    }
                    this.V.textArray = a

                    setTimeout(() => this.setState({}, () => { this.E50();this.Play() }), 100)

                } else {
                    message.error(e1.message)
                    this.V.isFirst=undefined
                }
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
                this.V.isFirst=undefined
            }
        ).finally(
            () => {
                this.V.disabled = false
                this.setState({})
            }
        )

    }
    V = {
        now: 0, t: 0, disabled: false, record: [],
        raudio: {},
        rs: 0,//0=就绪 1=录音 2=暂停录音 3=停止（录音和播放） 4=播放 5=播放暂停
        rsec: 0.00, rst: 0,
        rt: 0,
        isDone: 0,

    }

    render() {
        return (
            <div style={{ paddingTop: 30 }}>
                <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                    <h1>英语听读
                    <Tooltip placement="bottomLeft" title="可以在此练习英语听力，查看翻译、练习读音。读音练习部分只能在有麦克风的电脑上操作。">
                        <Button shape='circle' size='small' type='primary' style={{marginLeft:20}} >?</Button>
                    </Tooltip>
                </h1>
                    <div>

                        <Row>
                            <Col flex={20}>
                                请输入要进行英语听读学习的单词或句子：
                            </Col>
                            <Col flex={"auto"}>
                                <Button size="small" onClick={() => this.speechListen(true)} icon={<SyncOutlined />}>随机生成听读</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={20}>
                                <Input.TextArea disabled={this.V.disabled} value={this.state.input} onChange={x => this.setState({ input: x.target.value })}></Input.TextArea>
                            </Col>
                            <Col flex={"auto"}>
                                <Button onClick={this.speechListen} type="primary" shape="round" size="large" style={{ marginTop: 10, float: "right" }} disabled={this.V.disabled} icon={<CustomerServiceOutlined />}>提交听读预览</Button>
                            </Col>
                        </Row>
                    </div>
                    <audio ref={d => this.V.audio = d}></audio>
                    {this.V.JSON === undefined ? (
                        this.V.isFirst === undefined ? "" : <Alert type="info" showIcon style={{ marginTop: 10 }} message={"正在提交英语听读预览请求，服务器计算大约需要几秒钟，请稍等……"} />
                    ) : (
                        <>
                            <div style={{ marginTop: 20, border: "1px dashed #888", padding: 10 }}>
                                <h3>预览听读句子：</h3>
                                <Typography>{this.V.text}</Typography>
                                <div style={{ padding: 10, backgroundColor: "#FFFFD0" }}>
                                    {(Object.keys(this.V.FY).map((k, i) => {
                                        return <Typography key={i}>{k}：{this.V.FY[k].map((v, j) => <Typography.Text key={j} code>{v}</Typography.Text>)}</Typography>
                                    }))}
                                </div>


                                <Alert message={"当前音频长度：" + this.V.audio.duration.toFixed(1) + " 秒。 当前播放位置进度：" + this.V.audio.currentTime.toFixed(1) + " 秒（" + Math.ceil(100 * this.V.audio.currentTime / this.V.audio.duration) + "%）"} type="info" showIcon style={{ marginTop: 10 }} />

                                <Space style={{ marginTop: 10 }}>
                                    <Button shape="round" type="primary" size="small" onClick={this.Play} icon={<CaretRightOutlined />}>播放</Button>
                                    <Button shape="round" size="small" onClick={this.Pause} icon={<PauseOutlined />} >暂停</Button>
                                    <Button shape="round" type="danger" size="small" onClick={() => { this.Pause(); this.V.audio.currentTime = 0; this.E50() }} icon={<BorderOutlined />}>停止</Button>
                                </Space>
                            </div>
                            {this.V.recorder === undefined ? (
                                <Button shape="circle" type="primary" size="large" icon={<AudioOutlined />} style={{ marginTop: 20, width: 150 }} onClick={this.b_ready}>练习读音</Button>
                            ) : (
                                <div style={{ marginTop: 20, border: "1px dashed #888", padding: 10 }}>
                                    <audio ref={d => this.V.raudio = d}></audio>
                                    <h3>请练习读句子：</h3>
                                    <Typography style={{ fontSize: 16 }}>{this.V.input}</Typography>

                                    <Alert message={<span>点击【◉】开始录音，点击【■】结束录音，录音结束后点击【✓】提交服务器对你的发音进行评分。
                                        <br />{"录音长度不超过15秒；当前录音长度：" + this.V.rsec.toFixed(1) + " 秒。" + (this.V.raudio === null ? "" : (this.V.raudio.src === undefined ? "" : ("当前录音播放位置进度：" + this.V.raudio.currentTime.toFixed(1) + " 秒（" + Math.ceil(100 * this.V.raudio.currentTime / this.V.raudio.duration) + "%）")))}</span>
                                    } type="info" showIcon style={{ marginTop: 10 }} />


                                    <div style={{ border: "2px solid blue", borderRadius: 16, width: 220, backgroundColor: "#444", padding: 4, height: 52, marginTop: 10 }}>
                                        {this.V.rs === 1 ? (
                                            <div style={{ width: 38, display: "inline-flex" }}>
                                                <div style={{ position: "relative", top: 15 }}>
                                                    <div style={{ position: "absolute", height: 36, width: 36, bottom: 6 }}><AudioOutlined style={{ fontSize: 28, padding: 5, position: "absolute", bottom: 0, color: "white" }} /></div>
                                                    <div style={{ position: "absolute", height: 0, width: 36, bottom: 6, overflow: "hidden" }} ref={d => { this.V.icon = d }} ><AudioTwoTone style={{ fontSize: 28, padding: 5, position: "absolute", bottom: 0 }} /></div>
                                                </div>
                                            </div>
                                        ) : <AudioMutedOutlined style={{ fontSize: 28, padding: 5, color: "#888" }} />}

                                        {this.V.rs !== 1 ? (
                                            <Button type="danger" onClick={this.b1} style={{ margin: 1, fontSize: 26, padding: 0, lineHeight: "24px", width: 36, height: 36 }} disabled={this.V.rs > 3} >◉</Button>
                                        ) : (
                                            <Button type="danger" onClick={this.b2} style={{ margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36 }} ><PauseOutlined /></Button>
                                        )}

                                        {this.V.rs < 3 ? (<Button type="danger" onClick={this.b3a} style={{ margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36 }} disabled={this.V.rs === 0}><BorderOutlined /></Button>) : null}

                                        {this.V.rs === 3 ? (<Button onClick={this.b0} style={{ backgroundColor: "#D0D", margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36, color: "white" }}><RedoOutlined /></Button>) : null}

                                        {this.V.rs > 3 ? (<Button onClick={this.b3b} style={{ backgroundColor: "green", margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36, color: "white" }}><BorderOutlined /></Button>) : null}

                                        {this.V.rs !== 4 ? (
                                            <Button onClick={this.b4} style={{ backgroundColor: this.V.rs < 3 ? null : "green", margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36, color: this.V.rs < 3 ? null : "white" }} disabled={this.V.rs < 3}><CaretRightOutlined /></Button>
                                        ) : (
                                            <Button onClick={this.b5} style={{ backgroundColor: "green", margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36, color: "white" }}><PauseOutlined /></Button>
                                        )}

                                        <Button type="primary" onClick={this.b_ok}
                                            style={{ margin: 1, fontSize: 24, padding: 0, lineHeight: "30px", width: 36, height: 36, marginLeft: 10 }} disabled={this.V.rs < 3 || this.V.isDone > 0}><CheckOutlined /></Button>
                                    </div>
                                    {this.V.isDone > 0 ? (this.V.isDone > 1 ? (
                                        <Alert type="success" style={{ marginTop: 10 }} message={<div>
                                            <div>
                                                <h3>整体得分：</h3>
                                                <Space>
                                                    <Progress type="circle" strokeLinecap="butt" strokeColor={"#0C0"} trailColor={"#CCC"} strokeWidth={10} width={100} percent={this.V.F.PronScore} format={(percent) => <div style={{ marginTop: -10 }}><span style={{ fontSize: 14 }}>发音分数</span><br /><span style={{ lineHeight: "38px" }}>{percent}分</span></div>} ></Progress>
                                                    <Progress type="circle" strokeLinecap="butt" strokeColor={"#D44"} trailColor={"#CCC"} strokeWidth={10} width={100} percent={this.V.F.AccuracyScore} format={(percent) => <div style={{ marginTop: -10 }}><span style={{ fontSize: 14 }}>准确性</span><br /><span style={{ lineHeight: "30px" }}>{percent}分</span></div>} ></Progress>
                                                    <Progress type="circle" strokeLinecap="butt" strokeColor={"#46D"} trailColor={"#CCC"} strokeWidth={10} width={100} percent={this.V.F.FluencyScore} format={(percent) => <div style={{ marginTop: -10 }}><span style={{ fontSize: 14 }}>流畅性</span><br /><span style={{ lineHeight: "30px" }}>{percent}分</span></div>} ></Progress>
                                                    <Progress type="circle" strokeLinecap="butt" strokeColor={"#D0D"} trailColor={"#CCC"} strokeWidth={10} width={100} percent={this.V.F.CompletenessScore} format={(percent) => <div style={{ marginTop: -10 }}><span style={{ fontSize: 14 }}>完整性</span><br /><span style={{ lineHeight: "30px" }}>{percent}分</span></div>} ></Progress>
                                                </Space>
                                                <h3 style={{ marginTop: 16 }}>详细评分结果：</h3>
                                                <span style={{ fontSize: 16 }}><Space wrap>{this.V.a}</Space></span>
                                            </div>
                                        </div>} />) : (<Alert type="info" showIcon style={{ marginTop: 10 }} message={"正在提交你的读音到服务器计算，大约需要几秒钟，请稍等……"} />)
                                    ) : null}

                                </div>
                            )}


                        </>
                    )}

                </div>


            </div>
        )
    }

    b_ready = () => {
        this.V.audioContext = new (window.AudioContext || window.webkitAudioContext)();

        this.V.recorder = new Recorder(this.V.audioContext, {
            // An array of 255 Numbers
            // You can use this to visualize the audio stream
            // If you use react, check out react-wave-stream
            onAnalysed: d => {
                //console.log(data)
                let data = d.data
                Math.max(...data)
                if (this.V.icon) {
                    this.V.icon.style.height = (36 * Math.max(...data) / 255).toFixed(0) + "px"
                }

            },
        }, { numChannels: 1 });


        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => this.V.recorder.init(stream))
            .catch(err => message.error("不能录音！原因：" + err.toString()))


        this.V.rsec = 0.00
        this.V.rs = 0
        this.setState({})
    }
    b0 = () => {
        this.V.rs = 0
        this.V.rsec = 0.00
        this.V.recorder.clear()
        this.V.isDone = 0
        this.setState({})
    }
    b1 = () => {
        this.V.recorder.start().then(() => {
            this.V.rs = 1
            this.V.rst = (new Date).getTime() - this.V.rsec * 1000
            clearInterval(this.V.rt)
            this.V.rt = setInterval(() => {
                this.V.rsec = ((new Date).getTime() - this.V.rst) / 1000
                if (this.V.rsec > 15) {
                    this.b3a()
                }
                this.setState({})
            }, 50)
            //console.log("录音中...");
            this.V.isDone = 0
            this.setState({})
        })






    }
    b2 = () => {
        this.V.recorder.stop().then(({ blob, buffer }) => {
            this.V.blob = blob

            var audioURL = window.URL.createObjectURL(blob);
            this.V.raudio.src = audioURL;

            clearInterval(this.V.rt)
            this.V.rs = 2
            //console.log("录音结束");
            this.setState({})
        })

    }
    b3a = () => {
        this.V.recorder.stop().then(({ blob, buffer }) => {
            this.V.blob = blob
            var audioURL = window.URL.createObjectURL(blob);
            this.V.raudio.src = audioURL;

            clearInterval(this.V.rt)
            this.V.rs = 3
            //console.log("录音结束");
            this.setState({})
        })
    }
    b3b = () => {
        clearInterval(this.V.rt)
        this.V.raudio.pause()
        this.V.raudio.currentTime = 0
        this.V.rs = 3
        this.setState({})
    }
    b4 = () => {
        clearInterval(this.V.rt)
        this.V.rt = setInterval(() => {
            if (this.V.raudio.currentTime >= this.V.raudio.duration) {
                this.b3b()
            }
            this.setState({})
        }, 50)
        this.V.rs = 4
        this.V.raudio.play()
        this.setState({})
    }
    b5 = () => {
        clearInterval(this.V.rt)
        this.V.raudio.pause()
        this.V.rs = 5
        this.setState({})
    }
    b_ok = () => {
        this.V.isDone = 1
        this.setState({})



        let r = new FileReader
        r.onload = (e) => {
            let base64 = e.target.result
            fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ input: this.V.input, ticket: this.props.isLogin, what: "speechRead", base64: base64 }) }).then(
                e => {
                    return e.json()
                }
            ).then(
                e1 => {
                    if (e1.result==="Bad"){
                        message.error(e1.message)
                        return
                    }
                    //console.log("ok")
                    this.V.isDone = 2
                    let Data = e1.Data
                    //console.log(Data)
                    this.V.JG = Data.NBest[0].Words
                    this.V.F = Data.NBest[0].PronunciationAssessment

                    this.V.a = Data.NBest[0].Words.map((o, n) => {
                        if (o.Word === undefined) {
                            return <Typography.Text key={n} >{o}</Typography.Text>
                        } else {
                            return <Tooltip key={n} title={o.PronunciationAssessment.ErrorType === "Omission" ? "单词未读" : (<div>单词 {o.Word} {o.PronunciationAssessment.ErrorType === "Mispronunciation" ? "发音错误" : ""}{o.PronunciationAssessment.ErrorType === "Insertion" ? "多读" : ""} 得分：{o.PronunciationAssessment.AccuracyScore}<br />
                                <table border={1} cellPadding={4} style={{ borderColor: "rgba(0,0,0,0.1)", textAlign: "center" }} width={"100%"}>
                                    <tbody>
                                        <tr><td>音节</td>{o.Syllables === undefined ? null : o.Syllables.map((e, n) => <td key={n}>{e.Syllable}</td>)}</tr>
                                        <tr><td>得分</td>{o.Syllables === undefined ? null : o.Syllables.map((e, n) => <td key={n}>{e.PronunciationAssessment.AccuracyScore}</td>)}</tr>

                                    </tbody>

                                </table>
                                <table border={1} cellPadding={4} style={{ borderColor: "rgba(0,0,0,0.1)", textAlign: "center" }} width={"100%"}>
                                    <tbody>
                                        <tr><td>音素</td>{o.Phonemes.map((e, n) => <td key={n}>{e.Phoneme}</td>)}</tr>
                                        <tr><td>得分</td>{o.Phonemes.map((e, n) => <td key={n}>{e.PronunciationAssessment.AccuracyScore}</td>)}</tr>

                                    </tbody>

                                </table></div>)} color={o.PronunciationAssessment.ErrorType === "Mispronunciation" ? "orange" : (o.PronunciationAssessment.ErrorType !== "None" ? "red" : "green")} >
                                <Typography.Text style={{ color: "rgb(" + 255 * (100 - o.PronunciationAssessment.AccuracyScore) / 100 + "," + 128 * o.PronunciationAssessment.AccuracyScore / 100 + ",0)" }} mark={o.PronunciationAssessment.ErrorType === "Mispronunciation" ? true : false} delete={o.PronunciationAssessment.ErrorType === "Insertion" ? true : false} type={o.PronunciationAssessment.ErrorType === "Omission" ? "danger" : null}>{o.Word}</Typography.Text></Tooltip>
                        }

                    })

                }
            ).catch(
                ex => {
                    message.error("发生错误！原因：" + ex)
                }
            ).finally(
                () => {
                    this.V.disabled = false
                    this.setState({})
                }
            )
        }
        r.readAsDataURL(this.V.blob)




    }
    Play = () => {
        this.V.duration = this.V.audio.duration
        this.V.audio.play()
        this.V.t = setInterval(this.E50, 50)
    }
    Pause = () => {
        if (this.V.audio.currentTime < this.V.duration) {
            this.V.audio.pause()
        }

        clearInterval(this.V.t)
        this.setState({})

    }
    E50 = () => {
        if (this.V.audio !== undefined) {
            this.V.text = this.V.textArray.map((o, n) => {
                if (o.Word === undefined) {
                    return <Typography.Text key={n} >{o}</Typography.Text>
                } else
                    if (this.V.audio.currentTime * 10000000 >= o.Offset && this.V.audio.currentTime * 10000000 <= o.Offset + o.Duration) {
                        return <Typography.Text key={n} mark>{o.Word}</Typography.Text>
                    } else {
                        return <Typography.Text key={n} >{o.Word}</Typography.Text>
                    }

            })


            if (this.V.audio.currentTime >= this.V.duration) {

                this.Pause()
                return
            }
        }

        this.setState({})
    }
}


