import React from 'react';
import { Tooltip, Button, Input, Row, Col } from 'antd';
export class Key extends React.Component {
    constructor() {
        super()
        this.state = {
            neirong: "QWERTYUIOP[]\ASDFGHJKL;'ZXCVBNM,./1234567890-=`", shuliang: 200, xianshi: [], jihua: [],
            weizhi: 0, shiji: [], zhengque: 0, cuowu: 0, jishiqi: 0, bianhao: 0
            , chishu: 0
        }
    }
    setNr = (e) => {
        let v = e.target.value
        this.setState({ neirong: v })

    }
    setsl = (e) => {
        let v = e.target.value
        this.setState({ shuliang: v })
    }
    start = () => {
        this.dom.focus()
        let v = this.state.neirong
        let d = []
        for (let i = 0; i < this.state.shuliang; i++) {
            let sui = Math.floor(Math.random() * v.length)
            let abc = v[sui]
            d.push(abc)
        }
        this.setState({ jihua: d, weizhi: 0, shiji: [], zhengque: 0, cuowu: 0, jishiqi: (new Date()).getTime(), chishu: 0 }, this.show10)
        clearInterval(this.state.bianhao)
        let jishi = () => {
            this.setState({ jishiqi: this.state.jishiqi + 1 })
        }
        let a = setInterval(jishi, 10
        )
        this.setState({ bianhao: a })

    }
    show10 = () => {
        let sx = this.state.jihua.slice(this.state.weizhi, this.state.weizhi + 10)
        this.setState({ xianshi: sx, shiji: [] })
    }

    setsj = (e) => {
        // let v = e.target.value
        // this.setState({ shiji: v })
        //console.log(e)
        e.preventDefault()
        e.stopPropagation()
        let k = e.key
        if (k === "Enter" || k === " ") {
            this.show10()
            return
        }

        if (this.state.shiji.length === this.state.xianshi.length) {
            return
        }
        this.setState({ chishu: this.state.chishu + 1 }, () => {
            //console.log(88887)
            if (this.state.chishu == this.state.jihua.length) {
                //console.log(8888)
                clearInterval(this.state.bianhao)
                alert("做完了")
            }
        })
        if (k === this.state.jihua[this.state.weizhi]) {
            this.setState({ zhengque: this.state.zhengque + 1 })
            //console.log(1, this.state.zhengque)
        } else {
            this.setState({ cuowu: this.state.cuowu + 1 })
            //console.log(2, this.state.cuowu)
        }
        this.setState({ weizhi: this.state.weizhi + 1, shiji: [...this.state.shiji, k] })

    }








    render() {
        return (

            <div style={{ paddingTop: 30 }}>
                <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                    <h1>打字练习
                        <Tooltip placement="bottomLeft" title="练习键盘打字速度和正确率的应用。">
                            <Button shape='circle' size='small' type='primary' style={{ marginLeft: 20 }} >?</Button>
                        </Tooltip>
                    </h1>
                    <Row gutter={16}>
                        <Col>请输入要学的字符：<br /><Input value={this.state.neirong} onChange={this.setNr}  style={{width:400}}/></Col>
                        <Col>练习次数：<br /><Input type={"number"} value={this.state.shuliang} onChange={this.setsl} style={{width:100}}/></Col>
                        <Col><Button type={"primary"} size="large" onClick={this.start} style={{ marginTop: 5 }}>开始</Button></Col>
                    </Row>

                    <div style={{margin:10}}>
                        当前次数：{this.state.chishu}；耗时:<span>{
                            this.state.jishiqi === 0 ? 0 : (((new Date()).getTime() - this.state.jishiqi) / 1000).toFixed(2)

                        }</span>秒  平均速度:{this.state.chishu === 0 ? 0 : (((new Date()).getTime() - this.state.jishiqi) / 1000 / this.state.chishu).toFixed(2)}秒一个； 正确次数：{this.state.zhengque}  错误次数：{this.state.cuowu}  正确率：{(this.state.zhengque / this.state.chishu * 100).toFixed(2)}%； 错误率：{(this.state.cuowu / this.state.chishu * 100).toFixed(1)}%
                    </div>
                    <div id="DE"
                        style={{ fontFamily: 'Lucida Console', fontSize: 100, border: '3px solid red', backgroundColor: 'yellow', padding: 1, height: 140 }}>
                        {this.state.xianshi}
                    </div>
                    <p></p>
                    <div id="CD"
                        style={{ fontFamily: 'Lucida Console', fontSize: 100, border: '3px solid black', backgroundColor: '#88F', padding: 1, height: 140 }}>
                        {this.state.shiji.map((e, index) => {
                            let x = this.state.xianshi[index]
                            if (e === x) {
                                return <span key={index} style={{ color: 'green' }}>{e}</span>
                            } else {
                                return <span key={index} style={{ color: 'red' }}>{e}</span>
                            }
                        })}
                    </div>
                    <div style={{marginTop:10}}>练习输入焦点：<input value={this.null} ref={d=>this.dom=d} onKeyPress={this.setsj} style={{ width:100 }}></input></div>

                    
                </div>
            </div >
        )
    }
}
