import React, { useEffect, useState } from 'react';
import { PageHeader, Carousel, Button, message, Space, Popconfirm, Input } from 'antd';
import './main.css'
function Main(props) {
    let [zouMaDeng, setzouMaDeng] = useState([])
    let [count, setCount] = useState(0)
    let [text, setText] = useState(null)
    let [img, setImg] = useState(null)
    let [top, setTop] = useState(null)
    let [left, setLeft] = useState(null)
    let [color, setColor] = useState(null)
    let [fontSize, setFontSize] = useState(null)
    let [fontFamily, setFontFamily] = useState(null)
    let [isShow, setisShow] = useState(false)
    let [isNew, setisNew] = useState(false)
    //let me={}
    // console.log(props)
    // const contentStyle = {
    //     height: '160px',
    //     color: '#fff',
    //     lineHeight: '160px',
    //     textAlign: 'center',
    //     background: 'rgba(60,60,60,0.7)',
    // } 
    useEffect(() => {
        fetch("//happymade.cn/Blog/getdata.ashx?what=main", { method: 'GET' }).then(
            e => {
        
                return e.json()
            }
        ).then(
            ea => {
                setzouMaDeng(ea)
            }
        )
    }, [])
    const DB = () => {
        //console.log(count)
        fetch("//happymade.cn/Blog/getdata.ashx?what=main", { method: 'GET' }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            ea => {
                setzouMaDeng(ea)
            }
        )
    }
    const Delete = (key) => {
        if (zouMaDeng.length <= 2) {
            message.error("图片最少两页")

        } else {
            fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: key, what: "deleteMain", ticket: props.isLogin }) }).then(
                e => {
                    //console.log(e)
                    return e.json()
                }
            ).then(
                e1 => {
                    //console.log(e1)
                    if (e1.result === "OK") {
                        message.success(e1.message)
                        DB()
                        setCount(count + 1)
                    } else {
                        message.error(e1.message)
                    }

                }
            ).catch(
                ex => {
                    message.error("发生错误！原因：" + ex)
                }
            )
        }

    }

    const save = (key) => {
        if (img === "" || img === undefined) {
            message.error("图片链接未设置！")
            return
        }

        //console.log(isNew)
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: isNew ? undefined : key, text: text, img: img, top: top, left: left, color: color, fontSize: fontSize, fontFamily: fontFamily, what: "saveMain", ticket: props.isLogin }) }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                if (e1.result === "OK") {
                    message.success(e1.message)
                    DB()
                    setCount(count + 1)

                } else {
                    message.error(e1.message)
                }

            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }
    // let zouMaDeng = [
    //     { text: "海与狗", img: "7.jpg", style: { position: 'relative', top: '80%', left: '40%', color: "yellow" } },
    //     { text: "第7张", img: "8.jpg", style: { position: 'relative', top: '80%', left: '40%', color: "yellow" } },
    //     { text: "第三张", img: "3.jpg" },
    //     { text: "第四张", img: "4.jpg" },
    //     { text: "第五张", img: "5.jpg" }
    // ]
    // let caiDan = [
    //     { label: "首页", key: 1, className: "lineTop" },
    //     { label: "知识", key: 2, className: "lineTop" },
    //     { label: "读书", key: 3, className: "lineTop" },
    //     { label: "电影", key: 4, className: "lineTop" },
    //     { label: "关于", key: 5, className: "lineTop" },
    // ]
    return <>
        
        <Carousel autoplay style={{ width: '100vw', height: '100vh', zIndex: 100, position: "fixed", top: 0 }} beforeChange={() => setisShow(false)} initialSlide={isNew ? zouMaDeng.length - 1 : null}>
            {zouMaDeng.map((o, index) => {

                return (
                    <div key={index}>
                        {props.isLogin ?
                            <div style={{ float: "right", position: 'relative', right: 3, top: 108, textAlign: "right", zIndex: 500 }}>
                                <Space >
                                    <Button size='small' onClick={() => {
                                        if (isShow === false) {
                                            setColor("")
                                            setImg("")
                                            setLeft("")
                                            setText("")
                                            setTop("")
                                            setFontFamily("")
                                            setFontSize("")
                                            setisNew(true)
                                        }
                                        setisShow(!isShow)
                                    }}>添加</Button>
                                    <Button size='small' onClick={() => {
                                        if (isShow === false) {
                                            setColor(o.color)
                                            setImg(o.img)
                                            setLeft(o.left)
                                            setText(o.text)
                                            setTop(o.top)
                                            setisNew(false)
                                            setFontFamily(o.fontFamily)
                                            setFontSize(o.fontSize)
                                        }
                                        setisShow(!isShow)
                                    }}>编辑</Button>
                                    <Popconfirm
                                        title="是否要删除此条读书记录？"
                                        onConfirm={() => Delete(o.key)}
                                        okText="确认删除"
                                        cancelText="取消"
                                        okButtonProps={{ danger: true }}
                                    >
                                        <Button size='small' type="danger" >删除</Button>
                                    </Popconfirm>
                                </Space>
                                {isShow ? (<div style={{ height: 192, width: 300, backgroundColor: "rgba(200,200,200,0.4)", marginTop: 5, border: "1px dashed #888", borderRadius: 10 }}>
                                    <Input addonBefore="图像地址(字符串)" value={img} onChange={e => setImg(e.target.value)} />
                                    <Input addonBefore="文字内容(字符串)" value={text} onChange={e => setText(e.target.value)} />
                                    <Input addonBefore="文字颜色(字符串)" value={color} onChange={e => setColor(e.target.value)} />
                                    <Input addonBefore="文字大小(仅数字)" value={fontSize} onChange={e => setFontSize(e.target.value)} />
                                    <Input addonBefore="文字字体(字符串)" value={fontFamily} onChange={e => setFontFamily(e.target.value)} />
                                    <Input addonBefore="文字left(字符串)" value={left} onChange={e => setLeft(e.target.value)} />
                                    <Input addonBefore="文字top(字符串)" value={top} onChange={e => setTop(e.target.value)} />
                                    <Button type="primary" htmlType="submit" onClick={() => save(o.key)}>保存</Button>

                                </div>) : null}

                            </div>
                            : null}


                        <div style={{ width: '100vw', height: '100vh', backgroundImage: "url(" + o.img + ")", backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: '50%' }}>
                            <span style={{ ...o, position: "relative" }}>{o.text}</span>
                        </div>
                    </div>
                )
            }
            )
            }
        </Carousel>
        <div style={{position:"absolute",bottom:0,left:10,zIndex:999999,color:"rgba(128,128,128,0.5)",cursor:"pointer"}} onClick={()=>window.open("https://beian.miit.gov.cn/")}>蜀ICP备2020028308号-2</div>
    </>
}
export default Main