import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Main from "./main"
import Movie from "./movie"
//import 'antd/dist/antd.css';
import '@wangeditor/editor/dist/css/style.css'
import reportWebVitals from './reportWebVitals';
import { PageHeader } from '@ant-design/pro-layout';
import {  Menu, ConfigProvider, Modal, Form, Input, Button, message } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import Book from "./book"
import { Knowledge } from './knowledge';
import zhCN from 'antd/es/locale/zh_CN';
//import 'moment/locale/zh-cn';
import { Speech } from './Speech';
import { Guanyu } from './guanyu';
import { Boom } from "./boom";
import { Key } from "./key";
import { Map } from "./map";




function Head(props) {

    const Login = () => {
        const onFinish = (v) => {
            //console.log(v)
            v.what = "Login"
            fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify(v) }).then(
                e => {
                    //console.log(e)
                    return e.json()
                }
            ).then(
                e1 => {
                    //console.log(e1)
                    if (e1.result === "OK") {
                        message.success(e1.message)
                        props.setIsLogin(e1.ticket)
                        M.destroy()
                    } else {
                        message.error(e1.message)
                    }

                }
            ).catch(
                ex => {
                    message.error("发生错误！原因：" + ex)
                }
            )
        }

        const M = Modal.info({
            title: "管理登录...",
            maskClosable: true,
            centered: true,
            content: (
                <>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: '用户名不能为空！',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="请填写用户名" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: '密码不能为空！',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined />}
                                type="password"
                                placeholder="请填写管理密码"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            ),
            closable: true,
            icon: null,
            okButtonProps: { disabled: true, hidden: true },
            afterClose: () => {

            }

        });
    }
    const Logout = () => {
        props.setIsLogin("")
        message.info("管理已退出...")
    }

    let caiDan = [
        { label: "首页", key: "1", className: "lineTop" },
        { label: "知识", key: "2", className: "lineTop", image: "url(titlebg1.jpg)" },
        { label: "读书", key: "3", className: "lineTop", image: "url(titlebg2.jpg)" },
        { label: "电影", key: "4", className: "lineTop", image: "url(titlebg3.jpg)" },
        {
            label: "应用", key: "8", className: "lineTop", children: [
                { label: "英语听读", key: "9", className: "lineTop", image: "url(titlebg3.jpg)" },
                { label: "扫雷游戏", key: "10", className: "lineTop", image: "url(titlebg3.jpg)" },
                { label: "打字练习", key: "11", className: "lineTop", image: "url(titlebg3.jpg)" },
                { label: "找宝藏游戏", key: "12", className: "lineTop", image: "url(titlebg3.jpg)" },
            ]
        },

        { label: "管理", key: 6, className: "lineTop", hidden: props.isLogin === "" ? false : true },
        { label: "退出", key: 7, className: "lineTop", hidden: props.isLogin === "" ? true : false },
        { label: "关于", key: "5", className: "lineTop", image: "url(titlebg4.jpg)" },
    ]

    return (
        <PageHeader
            style={{
                backgroundImage: (k => {
                    for (let i = 0; i < caiDan.length; i++) {
                        if (caiDan[i].key == k && caiDan[i].children == undefined) {
                            return caiDan[i].image
                        } else if (caiDan[i].children !== undefined) {
                            for (let j = 0; j < caiDan[i].children.length; j++) {
                                if (caiDan[i].children[j].key == k) {
                                    return caiDan[i].children[j].image
                                }
                            }
                        }
                    }
                })(props.selectKey),
                backgroundRepeat: "no-repeat", backgroundSize: 'cover', backgroundPosition: '50%',
                width: '100%',
                backgroundColor: 'rgba(192, 192, 192, 0.2)',
                zIndex: 1000,
            }}
            title={<><img src='logo.jpg' width={64} height={64} style={{ marginRight: 5 }} />智之志</>}
            subTitle="杨智向的个人博客"
            extra={
                <Menu items={caiDan}
                    onSelect={(o) => {

                        if (o.key === "6") {
                            Login()
                        } else if (o.key === "7") {
                            Logout()
                        } else {
                            props.setSelectKey(o.key)
                        }
                    }}
                    theme={'dark'}
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                    className="noLine" mode="horizontal" selectedKeys={[props.selectKey]}>
                </Menu>
            }
        />
    )
}

function All() {
    let [selectKey, setSelectKey] = useState("1")
    let [isLogin, setIsLogin] = useState("")
    return (
        <ConfigProvider locale={zhCN}>

            <Head selectKey={selectKey} setSelectKey={setSelectKey} isLogin={isLogin} setIsLogin={setIsLogin} />

            {(function () {
                if (selectKey === "1") {
                    return <Main isLogin={isLogin} />
                } else if (selectKey === "4") {
                    return <Movie isLogin={isLogin} />
                } else if (selectKey === "3") {
                    return <Book isLogin={isLogin} />
                } else if (selectKey === "9") {
                    return <Speech isLogin={isLogin} />
                } else if (selectKey === "10") {
                    return <Boom />
                } else if (selectKey === "11") {
                    return <Key />
                } else if (selectKey === "12") {
                    return <Map />
                } else if (selectKey === "5") {
                    return <Guanyu isLogin={isLogin} />
                } else if (selectKey === "2") {
                    return <Knowledge isLogin={isLogin} />
                }
            })()}

        </ConfigProvider>
    )
}



// function SW() {
//   let [sw, setSw] = useState("1")


//   let show = undefined
//   if (sw === "1") {
//     show = <Main sw={sw} setSw={setSw} />
//   } else if (sw === "4") {
//     show = <Movie sw={sw} setSw={setSw} />
//   }

//   return (
//     <React.StrictMode>

//       {show}

//     </React.StrictMode>)
// }

// class SW1 extends React.Component {
//   constructor() {
//     super()
//     this.state = {key: "1" }
//   }
//   render() {
//     let show = undefined
//     if (this.state.key === "1") {
//       show = <Main waiThis={this} />
//     } else if (this.state.key === "4") {
//       show = <Movie waiThis={this} />
//     }

//     return (
//       <React.StrictMode>

//         {show}

//       </React.StrictMode>)
//   }
// }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<All />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
