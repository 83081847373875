import React from 'react';
import { Tooltip, Space, Button, Input, Modal, Row, Col } from 'antd';
export class Map extends React.Component {
    constructor(props) {
        super()
        this.state = {

            cishu: 0,
            jl: 0,
            text: "",
            sec:0,
            t:0
        }
     
        this.RV(600)
    }
    redo=()=>{
        this.setState( {

            cishu: 0,
            jl: 0,
            text: "",
            sec:0,
            t:0
        })
        this.RV(600)
    }
    RV = (v) => {
   
        let x = Math.floor(Math.random() * v)

        let y = Math.floor(Math.random() * v)

        Math.floor(Math.random() * v)
        setTimeout(() => {
            this.setState({ x: x, y: y })


        }, 100);
    }
    jlmc = (jl) => {
        if (jl < 10) {
            this.setState({ text: "就在这儿了！" })
        } else if (jl < 20) {
            this.setState({ text: "很近了！" })
        } else if (jl < 40) {
            this.setState({ text: "不远了！" })
        } else if (jl < 80) {
            this.setState({ text: "有点远了！" })
        } else if (jl < 160) {
            this.setState({ text: "太远了！" })
        } else if (jl < 320) {
            this.setState({ text: "太太太远了！" })
        } else { this.setState({ text: "远得没探测到宝藏！" }) }
    }


    A = (e) => {
  
        this.setState({ cishu: this.state.cishu + 1 })
        let eX = e.nativeEvent.offsetX
      
        let eY = e.nativeEvent.offsetY

        let jlX = eX - this.state.x
  
        let jlY = eY - this.state.y
    
        let jl = Math.sqrt(jlX * jlX + jlY * jlY)
        this.setState({ jl: jl })
        
        //$("#jl").text(cishu.toString() + this.jlmc(jl))

        setTimeout(() => {
            this.jlmc(this.state.jl)
        }, 100);




    }
    componentDidMount() {
        this.t = setInterval(this.setI, 1000)
    }
componentWillUnmount(){
    clearInterval(this.state.t)
}
    setI = () => {
        this.setState({ sec: this.state.sec + 1 })
       
    }

    render() {
        return (
            <div style={{ paddingTop: 30 }}>
                <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                <h1>找宝藏游戏
                    <Tooltip placement="bottomLeft" title="这块地里有一个宝藏，看你能在多少秒找得到。请用鼠标点击地图，根据提示最后找到宝藏准确位置。">
                        <Button shape='circle' size='small' type='primary' style={{marginLeft:20}} >?</Button>
                    </Tooltip>
                </h1>
                
                    <p><Button type={"primary"} onClick={this.redo} >重新开始</Button> <span>已用时：{this.state.sec}秒</span>，已点击：{this.state.cishu.toString()}次，最后点击情况： {this.state.text}</p>
                    
                    <img id="map" src="map.png" width="600" height="600" onMouseDown={this.A} />
                </div>

            </div>


        )
    }
}
