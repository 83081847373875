import React from 'react';
import './boom.css';
import { Layout, message, Space, Tooltip, Button, Input, Popconfirm, Typography, Modal, Row, Col } from 'antd';

export class Boom extends React.Component {
    index = 0
    showBox = []
    listBox = []//列；

    use = 0
    sec = 0
    t = 0
    constructor() {
        super()
        this.state = { w: 20, h: 15, boom: 50, sec: 0, result: '', lastBoom:50}//boom:雷的数量；
        this.init()
    }
    init = (e) => {
        this.nnn = 0
        this.use = 0
        this.index++
        this.showBox = []
        this.listBox = []
        for (let y = 0; y < this.state.h; y++) {
            let hang = []//行
            this.listBox.push(hang)

            for (let x = 0; x < this.state.w; x++) {  //showWhat 0-8 = 翻开的周围雷显示                               
                //showWhat -1=未翻开，-2=标记雷 -3=？  -4=爆炸   -5=其他未打开雷  -6=选错误的雷 ；
                let zt = { x: x, y: y, isBoom: false, showWhat: -1, mouseState: 0 }
                let B = <Box key={this.index + "-" + x + "-" + y} zt={zt} mouse3={this.mouse3} mouseDown={this.mouseDown} mouseUp={this.mouseUp} mouseClick={this.mouseClick} />
                hang.push(zt)
                this.showBox.push(B)
            }
            this.showBox.push(<br key={y} />)//换行；
        }
        for (let i = 0; i < this.state.boom; i++) { //布雷；
            var x = Math.floor(Math.random() * this.state.w)
            var y = Math.floor(Math.random() * this.state.h)
            let o = this.listBox[y][x]
            if (o.isBoom === true) {
                i--
            } else {
                o.isBoom = true
            }
        }
        if (this.t > 0) {
            clearInterval(this.t)
        }
        if (e !== undefined) {
            this.setState({ sec: 0, result: '' })
            this.t = setInterval(this.setI, 1000)
        }
  

    }
    componentDidMount() {
        this.t = setInterval(this.setI, 1000)
    }
    setI = () => {
        this.setState({ sec: this.state.sec + 1 })
    }

    mouse3 = (zt) => {                   //mouse3:右键；
        if (zt.showWhat < 0) {           //showWhat:方块(div)显示的类容；
            zt.showWhat--
            if (zt.showWhat === -2) {
                this.use++
                this.setState({})
            } else if (zt.showWhat === -3) {
                this.use--
                this.setState({})
                
            }
            if (this.use === this.state.boom) {
                let isok = true
                for (let y = 0; y < this.state.h; y++) {
                    for (let x = 0; x < this.state.w; x++) {
                        let zt = this.listBox[y][x]
                        if (zt.isBoom === true && zt.showWhat != -2) {
                            isok = false
                            break
                        }
                    }
                    if (isok === false) break
                }
                if (isok === true) {
                    this.setState({ result: '胜利了！' })
                    clearInterval(this.t)
                }

            }



            if (zt.showWhat < -3) zt.showWhat = -1

            zt.this.setState({})
        }
    }
    mouseDown = (zt, e) => {
        if (this.state.result !== "") return
        if (zt.mouseState === 0 || zt.mouseState === 1) {
            zt.mouseState++
        } else {
            zt.mouseState = 0
        }
    }
    mouseUp = (zt, e) => {
        if (this.state.result !== "") return
        if (zt.mouseState === 2 || zt.mouseState === 3) {
            zt.mouseState++         //不做其他只做双击
            if (zt.mouseState === 4) {
                zt.mouseState = 0
                if (zt.showWhat > 0) {
                    let n = 0
                    for (let y1 = zt.y - 1; y1 <= zt.y + 1; y1++) {
                        for (let x1 = zt.x - 1; x1 <= zt.x + 1; x1++) {
                            if (x1 >= 0 && x1 < this.state.w) {
                                if (y1 >= 0 && y1 < this.state.h) {
                                    if (!(y1 === zt.y && x1 === zt.x)) {
                                        let findZt = this.listBox[y1][x1]
                                        if (findZt.showWhat === -2) {
                                            n++
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (zt.showWhat === n) {
                        for (let y1 = zt.y - 1; y1 <= zt.y + 1; y1++) {
                            for (let x1 = zt.x - 1; x1 <= zt.x + 1; x1++) {
                                if (x1 >= 0 && x1 < this.state.w) {
                                    if (y1 >= 0 && y1 < this.state.h) {
                                        if (!(y1 === zt.y && x1 === zt.x)) {
                                            let findZt = this.listBox[y1][x1]
                                            if (findZt.showWhat === -1) { //翻开
                                                this.mouseClick(findZt)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            zt.mouseState = 0
            if (e.button === 0) {
                this.mouseClick(zt)
            } else if (e.button === 2) {
                this.mouse3(zt)
            }
        }
    }
    mouseClick = (zt) => {
        if (zt.showWhat === -2) {
            return   //标记为雷时 跳过；
        }
        if (zt.isBoom === true) {
            //点中雷，死亡，失败；            
            this.setState({ result: "你失败了！" })
            clearInterval(this.t)
            this.listBox.forEach(l => {
                l.forEach(o => {
                    if (o.isBoom === true) {
                        if (o.showWhat !== -2) {
                            o.showWhat = -5
                            o.this.setState({})
                        }
                    } else {
                        if (o.showWhat === -2) {
                            o.showWhat = -6
                            o.this.setState({})
                        }
                    }
                })
            })
            zt.showWhat = -4
            zt.this.setState({})//showWhat 0-8 = 翻开的周围雷显示数量；
            return              //showWhat -1=未翻开，-2=标记雷 -3=？  -4=爆炸   -5=其他未打开雷  -6=选错误的雷 ；
        }//计算周边并显示+ 已有数字显示不在计算；
        if (zt.showWhat === -1 || zt.showWhat === -3) {
            let n = 0
            for (let y1 = zt.y - 1; y1 <= zt.y + 1; y1++) {
                for (let x1 = zt.x - 1; x1 <= zt.x + 1; x1++) {
                    if (x1 >= 0 && x1 < this.state.w) {
                        if (y1 >= 0 && y1 < this.state.h) {
                            if (!(y1 === zt.y && x1 === zt.x)) {
                                let findZt = this.listBox[y1][x1]
                                if (findZt.isBoom === true) {
                                    n++
                                }
                            }
                        }
                    }
                }
            }
            zt.showWhat = n
            if (n === 0) {
                for (let y1 = zt.y - 1; y1 <= zt.y + 1; y1++) {       //点开一圈
                    for (let x1 = zt.x - 1; x1 <= zt.x + 1; x1++) {
                        if (x1 >= 0 && x1 < this.state.w) {
                            if (y1 >= 0 && y1 < this.state.h) {
                                if (!(y1 === zt.y && x1 === zt.x)) {
                                    let temp = this.listBox[y1][x1]
                                    if (temp.showWhat === -1) {
                                        this.mouseClick(temp)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            zt.this.setState({})
        }
    }
    setW = (e) => {
        let v = e.target.value
        if (this.state.boom <= this.state.h * v) {
            this.setState({ w: v }, () => this.init(1))
        }
    }
    setH = (e) => {
        let v = e.target.value
        if (this.state.boom <= this.state.w * v) {
            this.setState({ h: v }, () => this.init(1))
        }
    }
    setB = (e) => {
        let v = parseInt(e.target.value)
        if (v <= this.state.w * this.state.h) {
            this.setState({ boom: v ,lastBoom:v}, () => this.init(1))
        }
    }
    render() {


        return (<div style={{ paddingTop: 30 }}>
            <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                <h1>扫雷游戏
                    <Tooltip placement="bottomLeft" title="《扫雷》是一款大众类的益智小游戏，于1992年发行。游戏目标是在最短的时间内根据点击格子出现的数字找出所有非雷格子，同时避免踩雷，踩到一个雷即全盘皆输。我模仿其制作了网页版扫雷游戏，请用鼠标操作，支持鼠标左右两键同击，不支持触屏。">
                        <Button shape='circle' size='small' type='primary' style={{ marginLeft: 20 }} >?</Button>
                    </Tooltip>
                </h1>

                <div style={{ backgroundColor: '#EEE', width: this.state.w * 31 }}>
                    <div style={{ padding: 10 }}>
                        <Row gutter={16}>
                            <Col>设置宽度数：<Input type={"number"} value={this.state.w} onChange={this.setW} style={{ width: 60 }} /></Col>
                            <Col>设置高度数：<Input type={"number"} value={this.state.h} onChange={this.setH} style={{ width: 60 }} /></Col>
                            <Col>设置雷数量：<Input type={"number"} value={this.state.boom} onChange={this.setB} style={{ width: 60 }} /></Col>
                            <Col><Button type={"primary"} onClick={this.init} >重新开始</Button></Col>
                        </Row>

                        <div>
                            <span >{this.state.result === "" ? ("已用时：" + this.state.sec+"秒， ") : this.state.result}  </span>
                            <span >{"剩余"+(this.state.boom-this.use)+"颗雷"}  </span>
                        </div>
                    </div>
                    {this.showBox}
                </div>
            </div>
        </div>

        )
        // [<h1 key="aaa">{a}</h1>, <h2 key="111">{this.lost}</h2>, this.showBox]
    }
}
class Box extends React.Component {
    constructor(props) {
        super()
        props.zt.this = this
    }
    //showWhat 0-8 = 翻开的周围雷显示数量；
    //showWhat -1=未翻开，-2=标记雷 -3=？  -4=爆炸   -5=其他未打开雷  -6=选错误的雷 ；
    render() {
        let c = "", fc = "#000", s = this.props.zt.showWhat
        if (this.props.zt.showWhat >= 0) {
            c = "#FFF"
        }

        if (this.props.zt.showWhat === 1) {
            fc = "#00F"
        } else if (this.props.zt.showWhat === 2) {
            fc = "#080"
        } else if (this.props.zt.showWhat === 3) {
            fc = "#F00"
        } else if (this.props.zt.showWhat === 4) {
            fc = "#008"
        } else if (this.props.zt.showWhat === 5) {
            fc = "#880"
        }
        if (s === -2) {
            s = "⚑"
            fc = "#F00"
            //this.setState({lastBoom:lastBoom-1})
        }
        if (s === 0) {
            s = "　"
        }
        if (s === -3) {
            s = "？"
        }
        if (s === -4) {
            s = "☢"
            c = "#f00"
        }
        if (s === -5) {
            s = "☢"
        }
        if (s === -6) {
            s = "✘"
            c = "yellow"
        }
        //showWhat 0-8 = 翻开的周围雷显示数量；
        //showWhat -1=未翻开，-2=标记雷 -3=？  -4=爆炸   -5=其他未打开雷  -6=选错误的雷 ；
        return (
            <div
                className="box"
                onContextMenu={(e) => { e.preventDefault(); }}
                onMouseDown={(e) => { this.props.mouseDown(this.props.zt, e) }}
                onMouseUp={(e) => { this.props.mouseUp(this.props.zt, e) }}
                //onClick={(e) => { e.preventDefault(); this.props.mouseClick(this.props.zt) }}
                style={{ backgroundColor: c, color: fc }}
            >{s === -1 ? "　" : s}
                {/* {this.props.zt.isBoom ? "X" : "　"} */}
            </div>
        )
    }
}