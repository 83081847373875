import React, { useEffect, useState } from 'react';
import { Popover, Tag, Tooltip, Card, Typography, Row, Col ,Space,Button,Popconfirm,Modal,message} from 'antd';
import './main.css'
import EditBook from './editBook';

function Book(props) {
    const [modal,modalCH]=Modal.useModal()
    let [duShuLieBiao, setDuShuLieBiao] = useState([])
    let [count, setCount] = useState(0)
    useEffect(() => {
        //console.log(1)

        fetch("//happymade.cn/Blog/getdata.ashx?what=book", { method: 'GET' }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                setDuShuLieBiao(e1)
            }
        ).catch(
            ex => {
                //console.log(2222)
                //console.log(ex)
            }
        )

     
    }, [])
    const EditFunction = (o) => {
        let isOK = { isOK: false }
        modal.info({
            maskClosable: true,
            centered: true,
            width: '90%',
            content: <EditBook o={o} isOK={isOK} isLogin={props.isLogin} />,
            okText: "",
            closable: true,
            icon: null,
            okButtonProps: { disabled: true, hidden: true },
            afterClose: () => {
                if (isOK.isOK === true) {
                    DB()
                    setCount(count + 1)
                }

            }

        });
    }
    const DB = () => {
        //console.log(count)
        fetch("//happymade.cn/Blog/getdata.ashx?what=book", { method: 'GET' }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                setDuShuLieBiao(e1)
            }
        ).catch(
            ex => {
                message.error("发生错误，原因：" + ex)
            }
        )
    }
    const Delete = (key) => {
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: key, what: "deleteBook",ticket:props.isLogin }) }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                if (e1.result === "OK") {
                    message.success(e1.message)
                    DB()
                    setCount(count + 1)
                } else {
                    message.error(e1.message)
                }

            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }
    return <>
        <div style={{ paddingTop: 30 }}>{modalCH}
            <div style={{ minWidth: 280, width: '95%', maxWidth: 1250, margin: '0 auto', padding: 8 }}>
                <h1>读书记录 {props.isLogin ? <Button size='small' onClick={() => EditFunction()} >添加读书</Button> : null}</h1>
                <Row wrap gutter={[16, 16]}>
                    {
                        duShuLieBiao.map((o, index) => {
                            return (
                                <Col xs={24} lg={6} md={8} sm={12}  key={index} >
                                    
                                        <Card 
                                        onClick={() => window.open(o.url)}
                                            hoverable style={{ maxWidth: 400,padding:5,borderColor:"#CCC"}}
                                            cover={<img  style={{  height:300,objectFit:"scale-down"}} alt="example" src={"//happymade.cn/Blog/getdata.ashx?what="+o.image} />}
                                        >
                                            <Card.Meta title={o.name} description={"阅读日期：" + o.date1.substring(0,o.date1.indexOf("T"))+" ~ "+o.date2.substring(0,o.date2.indexOf("T"))} />
                                            <Card.Meta style={{ marginTop: 10, marginBottom: 10 }} description={
                                                <>进度：<span style={{ marginRight: 10 }}>{o.percent}%</span>
                                                    {(function () {
                                                        if (o.percent < 10) {
                                                            return <Tag color="red">几乎未读</Tag>
                                                        } else if (o.percent < 20) {
                                                            return <Tag color="pink">读了一点</Tag>
                                                        } else if (o.percent < 30) {
                                                            return <Tag color="volcano">看了部分</Tag>
                                                        } else if (o.percent < 40) {
                                                            return <Tag color="orange">三分之一</Tag>
                                                        } else if (o.percent < 50) {
                                                            return <Tag color="yellow">差点一半</Tag>
                                                        } else if (o.percent < 60) {
                                                            return <Tag color="cyan">读了一半</Tag>
                                                        } else if (o.percent < 70) {
                                                            return <Tag color="blue">一半有多</Tag>
                                                        } else if (o.percent < 80) {
                                                            return <Tag color="purple">读了大部分</Tag>
                                                        } else if (o.percent < 90) {
                                                            return <Tag color="geekblue">几乎读完</Tag>
                                                        } else if (o.percent > 90) {
                                                            return <Tag color="green">完整阅读</Tag>

                                                        }

                                                    })()}
                                                </>
                                            } />
                                            <Popover
                                                trigger={["click", "hover"]}
                                                placement={"bottom"}
                                                content={<div style={{ width: 200 }}>{o.feel}</div>} title="读后感">
                                                <Typography.Text
                                                    ellipsis={true}>{o.feel}</Typography.Text>
                                            </Popover>
                                            {props.isLogin ? (
                                            <div style={{ marginTop: 10 }} onClick={e => { e.stopPropagation() }}>
                                                <Space>
                                                    <Button size='small' onClick={() => EditFunction(o)}>编辑</Button>
                                                    <Popconfirm
                                                        title="是否要删除此条读书记录？"
                                                        onConfirm={() => Delete(o.key)}
                                                        okText="确认删除"
                                                        cancelText="取消"
                                                        okButtonProps={{ danger: true }}
                                                    >
                                                        <Button size='small' type="danger" >删除</Button>
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        ) : null}
                                        </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </div>
    </>
}
export default Book