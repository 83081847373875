import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { Input, Card, DatePicker, Row, Col, Space, Slider, Button, message, InputNumber, Tag, Popover, Typography } from 'antd';
import './main.css'
import { PictureOutlined, TagOutlined, LinkOutlined } from '@ant-design/icons'
const { RangePicker } = DatePicker
function EditBook(props) {

    let o = props.o
    if (o === undefined) o = {}
    let [image, setImage] = useState(o.image)
    let [name, setName] = useState(o.name)
    let [url, setUrl] = useState(o.url)
    let [dates, setDates] = useState([moment(o.date1), moment(o.date2)])
    let [percent, setPercent] = useState(o.percent)
    let [feel, setFeel] = useState(o.feel)
    let [isOK, setOK] = useState(false)

    let save = () => {
        if (image === "" || image === undefined) {
            message.error("书本图片未设置链接！")
            return
        }
        if (name === "" || name === undefined) {
            message.error("书本名称未设置！")
            return
        }
        if (percent === "" || percent === undefined) {
            message.error("阅读进度未设置")
            return
        }
        let po = { image: image, name: name, date1: (new Date(dates[0])).toLocaleDateString(), date2: (new Date(dates[1])).toLocaleDateString(), percent: percent, url: url, what: "postBook", key: o.key, feel: feel, ticket: props.isLogin }
        //console.log(JSON.stringify(po))
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify(po) }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                if (e1.result === "OK") {
                    message.success(e1.message)
                    props.isOK.isOK = true
                    setOK(true)
                } else {
                    message.error(e1.message)
                }

            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )

    }

    return <>

        <div style={{ paddingTop: 30 }}>
            <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                <h1>{props.o === undefined ? "添加读书记录" : "修改读书记录"}</h1>
                <Row wrap gutter={[16, 16]} >
                    <Col xs={24} lg={6} md={8} sm={12} >
                        读书卡片预览：
                        <Card
                            onClick={() => window.open(url)}
                            hoverable style={{ maxWidth: 400, padding: 5, borderColor: "#CCC" }}
                            cover={<img style={{ height: 300, objectFit: "scale-down" }} alt="example" src={"//happymade.cn/Blog/getdata.ashx?what=" + image} />}
                        >
                            <Card.Meta title={name} description={
                                "阅读日期：" + (
                                    () => {
                                        if (dates === null) {
                                            return null
                                        } else {
                                            return (dates[0] === undefined ? "" : (new Date(dates[0])).toLocaleDateString()) + " ~ " + (dates[1] === undefined ? "" : (new Date(dates[1])).toLocaleDateString())
                                        }
                                    }
                                )()
                            }
                            />
                            <Card.Meta style={{ marginTop: 10, marginBottom: 10 }} description={
                                <>进度：<span style={{ marginRight: 10 }}>{percent}%</span>
                                    {(function () {
                                        if (percent < 10) {
                                            return <Tag color="red">几乎未读</Tag>
                                        } else if (percent < 20) {
                                            return <Tag color="pink">读了一点</Tag>
                                        } else if (percent < 30) {
                                            return <Tag color="volcano">看了部分</Tag>
                                        } else if (percent < 40) {
                                            return <Tag color="orange">三分之一</Tag>
                                        } else if (percent < 50) {
                                            return <Tag color="yellow">差点一半</Tag>
                                        } else if (percent < 60) {
                                            return <Tag color="cyan">读了一半</Tag>
                                        } else if (percent < 70) {
                                            return <Tag color="blue">一半有多</Tag>
                                        } else if (percent < 80) {
                                            return <Tag color="purple">读了大部分</Tag>
                                        } else if (percent < 90) {
                                            return <Tag color="geekblue">几乎读完</Tag>
                                        } else if (percent > 90) {
                                            return <Tag color="green">完整阅读</Tag>

                                        }

                                    })()}
                                </>
                            } />
                            <Popover
                                trigger={["click", "hover"]}
                                placement={"bottom"}
                                content={<div style={{ width: 200 }}>{feel}</div>} title="读后感">
                                <Typography.Text
                                    ellipsis={true}>{feel}</Typography.Text>
                            </Popover>

                        </Card>
                    </Col>
                    <Col flex={"auto"} style={{ border: "1px solid #CCC" }}>
                        <Space direction="vertical" style={{ width: '100%', margin: '8px auto' }}>
                            请在下方填写读书相关信息：
                            <Input maxLength={98} disabled={isOK} placeholder="请填写书本图片链接" prefix={<PictureOutlined />} value={image} onChange={e => { setImage(e.target.value) }} />
                            <Input maxLength={95} disabled={isOK} placeholder="请填写书本名称" prefix={<TagOutlined />} value={name} onChange={e => { setName(e.target.value) }} />
                            <Input maxLength={490} disabled={isOK} placeholder="请填写豆瓣读书词条链接" prefix={<LinkOutlined />} value={url} onChange={e => { setUrl(e.target.value) }} />
                            <RangePicker disabled={isOK} placeholder={["请选择起始日期", "请选择结束日期"]} onChange={d => {  return setDates(d) }} value={dates} />
                            <Row>
                                <Col>
                                    请设置阅读进度：
                                </Col>
                                <Col flex={"auto"}>
                                    <Slider
                                        min={0}
                                        max={100}
                                        onChange={setPercent}
                                        value={percent}
                                        tooltip={{ formatter: (v => v + "%") }}
                                    />
                                </Col>
                                <Col>
                                    <InputNumber
                                        formatter={v => v + "%"}
                                        min={0}
                                        max={100}
                                        style={{
                                            margin: '0 16px',
                                        }}
                                        value={percent}
                                        onChange={setPercent}
                                    />
                                </Col>
                            </Row>
                            <Input.TextArea rows={4} placeholder="请输入读后感" maxLength={490} value={feel} onChange={e => { setFeel(e.target.value) }} />

                            <Button disabled={isOK} type="primary" onClick={save}>{props.o === undefined ? "确定添加" : "修改保存"}</Button>
                            {isOK ? "已操作成功，请关闭。" : ""}
                        </Space>
                    </Col>
                </Row>

            </div>
        </div>
    </>
}
export default EditBook
