import React from "react";
import { Input, Button, List, message,  Modal, Space } from 'antd';
import { Comment } from '@ant-design/compatible';
import { UserOutlined } from '@ant-design/icons';
export class Guanyu extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            name: "",
            text: "",
            //comment: [],
            isOK: false,
            onOk: false,
            Comment: [],

        }
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ ticket: this.props.isLogin, what: "readComment" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                this.setState({ Comment: e1 })
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }
    render() {
        return (
            <div style={{ padding: 40 }}>


                <img src="logo.jpg" style={{ float: "right", margin: 20 }} />
                <span style={{ fontSize: 24 }}>大家好，我是杨智向，阳光帅气的我在 2012年5月 出生于四川都江堰青城山；
                    按照你浏览器时间计算，目前我已经 <span style={{ color: "blue" }}>{(((new Date).getTime() - (new Date(2012, 4, 19, 20, 0, 0)).getTime()) / 1000 / 24 / 365 / 3600).toFixed(6)}</span> 岁了。我非常喜欢 读书、计算机编程、电子电路。<br /><br />
                    博客取名“智之志”，旨在记录并分享我2023年起我学到的一些知识、看过的书籍、电影等。<br /><br />
                    该博客是我在 2022年8月 至 2023年1月期间，在我爸爸的指导下，耗时半年，博客一点点的从零建立起来的。这期间我学习了 HTML、JavaScript、JSON、React、Visual Basic、SQL Server、IIS、ASP.net 等相关开发技术，整个博客的几乎所有的代码都是我自己编写的。<br /><br />
                    同时我自己的还搭建了 Windows Server 2022 虚拟服务器，该服务器承载着我的所有开发环境和博客网站服务环境。我都是通过远程桌面连接到该服务器进行开发、调试、编译、发布。该服务器位于公网，请各位高手手下留情。<br /><br />
                    既然你都看到这了，你就 <Button size={"large"} type="primary" onClick={() => this.setState({ onOk: true })}>留个言</Button> 吧!<br /><br />
                    首次发布于 2023年1月11日
                </span>

                <div>
                    <Modal title="留言板" open={this.state.onOk} onCancel={() => this.setState({ onOk: false, isOK: false, name: "", text: "" })}
                        footer={this.state.isOK ? <span style={{ color: "green" }}>你的留言我已收到~，谢谢！ 你的留言暂时只能我看到，待我审核后将公开。</span> : <span style={{ color: "#888" }}>你的留言提交后，暂时只能我看到，待我审核后将公开。</span>}  >
                        <Input maxLength={95} prefix={<UserOutlined />} disabled={this.state.isOK} placeholder="请填写你的昵称" value={this.state.name} onChange={e => { this.setState({ name: e.target.value }) }} style={{ marginBottom: 7 }} /><br />
                        <Input.TextArea maxLength={490} disabled={this.state.isOK} placeholder="你有啥想对我说的，请写下来吧！" value={this.state.text} onChange={e => { this.setState({ text: e.target.value }) }} style={{ marginBottom: 7 }} autoSize /><br />
                        <Button type="primary" disabled={this.state.isOK} size="large" onClick={this.save}>提交</Button><br />

                    </Modal>
                </div>
                <div style={{ border: "2px dotted #AAA", padding: 20, borderRadius: 20, marginTop: 20 }}>
                    <List
                        header={"最近10条公开的留言："}
                        itemLayout="horizontal"
                        dataSource={this.state.Comment}
                        renderItem={(item) => (
                            <li>
                                <Comment
                                    author={item.name}
                                    avatar={<UserOutlined />}
                                    content={item.content}
                                    datetime={<span style={{ marginLeft: 20 }}>留言时间：{item.date.replace("T", " ")}</span>}
                                />{this.props.isLogin ? <Space><Button type="danger" size="small" onClick={() => { this.delete(item.key) }}>删除</Button>{item.isOpened ? null : <Button type="primary" size="small" onClick={() => { this.open(item.key) }}>公开</Button>}</Space> : null}
                            </li>
                        )}
                    />
                </div>

            </div>
        )
    }

    delete = (key) => {

        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ ticket: this.props.isLogin, key: key, what: "deleteComment" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                if (e1.result === "Bad") {
                    message.success(e1.message)
                } else {
                    message.success("已删除")
                    this.setState({ Comment: e1 })
                }
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )



    }
    open = (key) => {

        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ ticket: this.props.isLogin, key: key, what: "openComment" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)

                if (e1.result === "Bad") {
                    message.success(e1.message)
                } else {
                    message.success("已公开")
                    this.setState({ Comment: e1 })
                }
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )



    }
    save = () => {

        if (this.state.name === "" || this.state.name === undefined) {
            message.warning("你的昵称未填写哦~")
            return
        }
        if (this.state.text === "" || this.state.text === undefined) {
            message.warning("留言内容是空的！没有没有啥想说的？")
            return
        }


        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ name: this.state.name, text: this.state.text, what: "WriteComment" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                this.setState({ isOK: true, name: "", text: "" })
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )



    }
}
