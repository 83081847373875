import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { Input, Card, DatePicker, Row, Col, Space, Rate, Button, Tooltip, message } from 'antd';
import './main.css'
import { PictureOutlined, TagOutlined, LinkOutlined } from '@ant-design/icons'
function EditMovie(props) {

    let o = props.o
    if (o === undefined) o = {}
    let [image, setImage] = useState(o.image)
    let [name, setName] = useState(o.name)
    let [url, setUrl] = useState(o.url)
    let [date, setDate] = useState(moment(o.date))
    let [star, setStar] = useState(o.star > 0 ? o.star : 3)
    let [isOK, setOK] = useState(false)

    let save = () => {
        if (image === "" || image === undefined) {
            message.error("电影海报未设置链接！")
            return
        }
        if (name === "" || name === undefined) {
            message.error("电影名称未设置！")
            return
        }

        let po = { image: image, name: name, date: (new Date(date)).toLocaleDateString(), star: star, url: url, what: "postMovie", key: o.key, ticket: props.isLogin }
       // console.log(JSON.stringify(po))
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify(po) }).then(
            e => {
                //console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log(e1)
                if (e1.result === "OK") {
                    message.success(e1.message)
                    props.isOK.isOK = true
                    setOK(true)
                } else {
                    message.error(e1.message)
                }

            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )

    }
    //console.log(props.o)
    return <>

        <div style={{ paddingTop: 30 }}>
            <div style={{ minWidth: 280, width: '95%', maxWidth: 1200, margin: '0 auto', padding: 8 }}>
                <h1>{props.o === undefined ? "添加观影记录" : "修改观影记录"}</h1>
                <Row wrap gutter={[16, 16]} >
                    <Col xs={24} lg={6} md={8} sm={12} >
                        电影卡片预览：
                        <Card onClick={() => {
                            if (!(url == "")) window.open(url)
                        }}
                            hoverable style={{ maxWidth: 400, padding: 5, borderColor: "#CCC" }}
                            cover={<img style={{ maxHeight: 400, objectFit: "scale-down" }} alt="电影海报图片" src={"//happymade.cn/Blog/getdata.ashx?what=" + image} />}
                        >
                            <Card.Meta title={name} description={"观看日期：" + (date === 0 ? '' : (new Date(date)).toLocaleDateString())} />
                            <Tooltip title={"个人评分：" + star}>
                                <div></div><Rate allowHalf value={star} disabled />
                            </Tooltip>
                        </Card>

                    </Col>
                    <Col flex={"auto"} style={{ border: "1px solid #CCC" }}>
                        <Space direction="vertical" style={{ width: '100%', margin: '8px auto' }}>
                            请在下方填写电影相关信息：
                            <Input maxLength={98} disabled={isOK} placeholder="请填写电影海报图片链接" prefix={<PictureOutlined />} value={image} onChange={e => { setImage(e.target.value) }} />
                            <Input maxLength={95} disabled={isOK} placeholder="请填写电影名称" prefix={<TagOutlined />} value={name} onChange={e => { setName(e.target.value) }} />
                            <Input maxLength={490} disabled={isOK} placeholder="请填写豆瓣电影词条链接" prefix={<LinkOutlined />} value={url} onChange={e => { setUrl(e.target.value) }} />
                            <DatePicker disabled={isOK} placeholder="请选择观看日期" onChange={d => { setDate(d) }} value={date} />

                            <Rate disabled={isOK} allowHalf value={star} onChange={setStar} />

                            <Button disabled={isOK} type="primary" onClick={save}>{props.o === undefined ? "确定添加" : "修改保存"}</Button>
                            {isOK ? "已操作成功，请关闭。" : ""}
                        </Space>
                    </Col>
                </Row>

            </div>
        </div>
    </>
}
export default EditMovie