import React from "react";
import { LaptopOutlined, NotificationOutlined, UserOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, message, List, Space, Tooltip, Button, Input, Popconfirm, Typography, Modal } from 'antd';
import Item from "antd/lib/list/Item";
import { configConsumerProps } from "antd/lib/config-provider";
import { EditKnowledge } from "./editKnowledge";
import Prism from "prismjs"
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-visual-basic';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-xml-doc';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-jsx';
//import 'prismjs/components/prism-go';
//import 'prismjs/components/prism-php';
//import 'prismjs/components/prism-bash';
//import 'prismjs/components/prism-lua';
//import 'prismjs/components/prism-groovy';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-sql';
//import 'prismjs/components/prism-ruby';
//import 'prismjs/components/prism-swift';


import "prismjs/themes/prism-coy.min.css"



const { Header, Content, Sider } = Layout;


export class Knowledge extends React.Component {
    V = { Input: [] }
    constructor(props) {
        super()
        this.state = {
            caiDan: [],
            Kind: [],
            List: [],
            showWhat: "",
            edit: [],
            listkind: null,
            menuOnClick: null,
            isLogin: "",
            count: 0
        }

        this.init()


    }
    init = () => {

        fetch("//happymade.cn/Blog/getdata.ashx?what=knowledge", { method: 'GET' }).then(
            e => {
                ////console.log(e)
                return e.json()
            }
        ).then(
            e1 => {
                ////console.log(e1)
                let zhuCaiDan = e1.filter(x => x.fid === null ? true : false)
                zhuCaiDan.forEach(d => { if (d.key !== 0) d.children = [] })
                ////console.log(zhuCaiDan)
                this.setState({ zhuCaiDan: zhuCaiDan.map(a => a.key.toString()) })
                ////console.log(zhuCaiDan.map(a => a.key.toString()))
                this.V.yuanShiCaiDan = e1



                e1.forEach(d => {

                    if (d.fid !== null) {
                        let s = zhuCaiDan.find(f => f.key === d.fid ? true : false
                        )
                        // if (s.children === undefined) {
                        //     s.children = [d]
                        // } else {
                        s.children.push(d)
                        //}
                    }
                })
                zhuCaiDan.forEach((d) => {
                    if (d.key !== 0) {
                        d.children.push({
                            key: -d.key, label: <Input.Group compact>
                                <Input ref={d => this.I = d} style={{ width: 100 }} onChange={e => this.V.Input[-d.key] = e.target.value} />
                                <Button type="primary" icon={<CheckOutlined />} onClick={e => this.postMenu(this.V.Input[-d.key], d.key)}></Button>
                            </Input.Group>, hidden: true
                        })
                    }
                })
                zhuCaiDan.push({
                    key: "", label: <Input.Group compact>
                        <Input style={{ width: 130 }} onChange={e => this.V.Input[0] = e.target.value} value={this.V.Input[0]} />
                        <Button type="primary" icon={<CheckOutlined />} onClick={e => this.postMenu(this.V.Input[0])}></Button>
                    </Input.Group>, hidden: true
                })
                //console.log("init1")
                this.V.isLogin = ""
                this.setState({ caiDan: zhuCaiDan })

            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ kind: -1, what: "listKnowledge" }) }).then(
            e => {
                ////console.log(e, 1111111111111111)
                return e.json()
            }
        ).then(
            e1 => {
                //console.log("init2")
                this.setState({ List: e1 })
                ////console.log(e1)


            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
        //console.log("init0")
    }
    changeKind = (x) => {
        //console.log(x)
        if (x.key < 0 || x.key === "") {
            return
        }

        //console.log(this.V.yuanShiCaiDan)
        let my = this.V.yuanShiCaiDan.find(e => e.key.toString() == x.key.toString())
        //console.log(my)
        let path=[]
        if (my.key==0){
            path=[my]
        }else if (my.fid===null){
           this.changeKind({key:this.state.menuSelectedKey})
            return
        }else{
            let f = this.V.yuanShiCaiDan.find(e => e.key == my.fid)
            path = [my, f]
            //console.log(f)
        }
        

    
        //console.log(path)
        this.setState({ Kind: path.reverse(), menuSelectedKey: x.key.toString() })


        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ kind: x.key, what: "listKnowledge" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                this.setState({ showWhat: "list", List: e1, listkind: x.key, menuOnClick: x })
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )

    }

    content = (x) => {
        ////console.log(x)



        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: x, what: "readKnowledge" }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                this.setState({ showWhat: "content", List: e1 })
                ////console.log(e1, 99999999)
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )



    }
    postMenu = (a, b) => {
        if (a === undefined || a === "") {
            message.warning("新分类名不能为空！")
            return
        }
        Modal.confirm({
            title: "是否添加新分类？",
            content: "是否添加名为：【" + a + "】的分类？",
            onOk: () => {
                fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ label: a === undefined ? "" : a, fid: b, ticket: this.props.isLogin, what: "postMenu" }) }).then(
                    e => {
                        return e.json()
                    }
                ).then(
                    e1 => {

                        this.init()
                        if (e1.result === "OK") {
                            message.success(e1.message)
                        } else {
                            message.error(e1.message)
                        }
                    }
                ).catch(
                    ex => {
                        message.error("发生错误！原因：" + ex)
                    }
                )
            }
        })


    }
    deleteMenu = (key) => {
        //console.log(key)
        fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ key: key, ticket: this.props.isLogin, what: "deleteMenu", }) }).then(
            e => {
                return e.json()
            }
        ).then(
            e1 => {
                //console.log("delete1")
                this.init()
                //console.log("delete2")
                if (e1.result === "OK") {
                    message.success(e1.message)
                } else {
                    message.error(e1.message)
                }
            }
        ).catch(
            ex => {
                message.error("发生错误！原因：" + ex)
            }
        )
    }
    componentDidUpdate() {
        //console.log(this.props.isLogin,this.V.isLogin)
        if (this.V.isLogin !== this.props.isLogin) {
            //console.log(44)
            this.V.isLogin = this.props.isLogin
            //console.log(this.state.caiDan)
            this.state.caiDan.forEach(a => {
                //console.log(55)
                if (a.hidden !== undefined) {
                    a.hidden = this.props.isLogin ? false : true
                    //console.log(66)
                } else {
                    //console.log(77)
                    if (a.children !== undefined) {
                        //console.log(88)
                        if (a.children.length > 0) {
                            //console.log(99)
                            a.children.forEach(b => {
                                //console.log(0)
                                ////console.log(b)
                                if (b.hidden !== undefined) {
                                    b.hidden = this.props.isLogin ? false : true
                                } else {
                                    //console.log(11)
                                    if (typeof (b.label) === "string" && this.props.isLogin) {
                                        //console.log(12)
                                        b.label = [<Popconfirm
                                            title={"是否要删除【" + b.label + "】分类吗？"}
                                            onConfirm={() => this.deleteMenu(b.key)}
                                            okText="确认删除"
                                            cancelText="取消"
                                            okButtonProps={{ danger: true }}
                                        >
                                            <Button key="1" onClick={e => { e.preventDefault(); e.stopPropagation(); }} type="danger" shape="circle" size="small" style={{ marginRight: 10 }}>X</Button>
                                        </Popconfirm>, b.label]
                                    } else if (typeof (b.label) === "object" && !this.props.isLogin) {
                                        //console.log(13)
                                        b.label = b.label[1]
                                    }
                                }
                            })
                        }
                        if (typeof (a.label) === "string" && this.props.isLogin) {
                            a.label = [<Popconfirm
                                title={"是否要删除【" + a.label + "】分类吗？"}
                                onConfirm={() => this.deleteMenu(a.key)}
                                okText="确认删除"
                                cancelText="取消"
                                okButtonProps={{ danger: true }}
                            >
                                <Button key="1" onClick={e => { e.preventDefault(); e.stopPropagation(); }} type="danger" shape="circle" size="small" style={{ marginRight: 10 }}>X</Button>
                            </Popconfirm>, a.label]
                        } else if (typeof (a.label) === "object" && !this.props.isLogin) {
                            a.label = a.label[1]
                        }
                    }

                }

            })
            this.setState({})
        }
        Prism.highlightAll()
    }

    changename = (x, y) => {
        Modal.confirm({
            title: "是否修改分类名？",
            content: "是否修改当前分类名为：【" + x + "】",
            onOk: () => {
                //console.log(x, y)
                fetch("//happymade.cn/Blog/getdata.ashx", { method: 'POST', body: JSON.stringify({ name: x, key: y, ticket: this.props.isLogin, what: "changeKindName" }) }).then(
                    e => {
                        return e.json()
                    }
                ).then(
                    e1 => {
                        if (e1.result === "OK") {
                            message.success(e1.message)
                            this.init()
                            setTimeout(() => this.changeKind({ key: y }), 200)
                        } else {
                            message.error(e1.message)
                        }
                        this.setState({})
                    }
                )
                // .catch(
                //     ex => {
                //         message.error("发生错误！原因：" + ex)
                //     }
                // )
            }
        })

    }
    render() {
        return (

            <Layout>

                <Layout>
                    <Sider width={200} className="site-layout-background">
                        {this.state.zhuCaiDan === undefined ? null : <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}

                            style={{
                                height: '100%',
                                borderRight: 0,
                            }}
                            items={this.state.caiDan.map(d => d)}
                            onClick={this.changeKind}
                            defaultOpenKeys={this.state.zhuCaiDan}
                            selectedKeys={[this.state.menuSelectedKey]}
                        />}

                    </Sider>
                    <Layout
                        style={{
                            padding: '0 24px 24px',
                        }}
                    >
                        {this.state.Kind.length === 0 ? <p style={{ fontSize: 14, paddingTop: 18 }}>最新知识（共 {this.state.List.length} 篇）</p> : (<>
                            <Breadcrumb
                                style={{
                                    margin: '16px 0',
                                }}
                            >{this.state.Kind.map((e, index) => {
                               // console.log(e)
                                return <Breadcrumb.Item style={{ cursor: "pointer" }} key={index} onClick={() => this.changeKind(this.state.menuOnClick)}>
                                    <Typography.Text editable={this.props.isLogin ? { onChange: text => this.changename(text, e.key) } : undefined}>{typeof (e.label) === "string" ? e.label : e.label[1]}</Typography.Text>
                                </Breadcrumb.Item>
                            })}
                            </Breadcrumb>
                            {this.state.showWhat === "list" ? (<div style={{ border: "1px dashed #888", backgroundColor: "#FFF", padding: 10, marginBottom: 15 }}>
                                <span>该栏目下共有 {this.state.List.length} 篇文章</span>
                                {this.props.isLogin ? <Button style={{ float: "right" }} type="primary" size="small" onClick={() => { this.setState({ showWhat: "edit", edit: null }) }}>添加文章</Button> : null}
                            </div>) : null}

                        </>)}


                        <Content
                            //className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                                backgroundColor: "white"
                            }}
                        >
                            {
                                (() => {
                                    ////console.log(this.state)
                                    if (this.state.showWhat === "list") {

                                        return (<List
                                            style={{  wordBreak:"break-all",}}
                                            itemLayout="vertical"
                                            size="large"
                                            pagination={{

                                                pageSize: 10,
                                                hideOnSinglePage: true,
                                                showQuickJumper: true,
                                                position: "both"
                                            }}
                                            dataSource={this.state.List}

                                            renderItem={(item) => (
                                                <List.Item
                                                    key={item.key}
                                                    actions={[
                                                        <>发布时间: {item.date.replace("T", " ")}</>,
                                                        <>阅读次数: {item.count}</>,


                                                    ]}
                                                // extra={
                                                //     <img
                                                //         width={272}
                                                //         alt="logo"
                                                //         src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                                                //     />
                                                // }
                                                >
                                                    <List.Item.Meta
                                                        //   avatar={<Avatar src={item.avatar} />}
                                                        title={<a onClick={() => this.content(item.key)}>{item.title}</a>}
                                                        description={this.state.List[0] == "" ? null : this.getSimpleText(item.sammary).substr(0, 70)}
                                                    />
                                                    {this.getSimpleText(item.content).substr(0, 100)}

                                                </List.Item>
                                            )}
                                        />)
                                    } else if (this.state.showWhat === "content") {

                                        return (<div>
                                            <div style={{ fontSize: 28, fontWeight: "bold" }}>{this.state.List[0].title}</div>
                                            <div style={{ backgroundColor: "#EEE", padding: 10, height: 50, lineHeight: "30px", marginTop: 10, marginBottom: 20 }}>发布时间：{this.state.List[0].date.replace("T", " ")}<span style={{ marginLeft: 30 }}>阅读次数：{this.state.List[0].count}</span>{this.props.isLogin ? <Button style={{ float: "right" }} onClick={() => { this.setState({ showWhat: "edit", edit: this.state.List[0] }) }}>编辑文章</Button> : null}</div>

                                            {/* {
                                                (() => {
                                                    if (this.state.showWhat === "edit") {
                                                        //console.log(this.state.List[0])
                                                        return (<EditKnowledge/>)
                                                    }
                                                }
                                                )()
                                                } */}


                                            {this.state.List[0].sammary == "" ? null : <div style={{ backgroundColor: '#DDD', padding: 15, fontSize: 16, border: "1px dashed #444", wordBreak: "break-all" }} dangerouslySetInnerHTML={{ __html: this.state.List[0].sammary }}></div>}
                                            <div dangerouslySetInnerHTML={{ __html: this.state.List[0].content }} ></div>
                                        </div>)
                                    } else if (this.state.showWhat === "edit") {
                                        return (<EditKnowledge item={this.state.edit} kind={this.state.listkind} content={this.content} isLogin={this.props.isLogin} changeKind={this.changeKind} caiDan={this.state.caiDan} yuanShiCaiDan={this.V.yuanShiCaiDan} />)
                                    } else {
                                        return (
                                            <List
                                                style={{ width: "100%", wordBreak:"break-all",
                                                //overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" 
                                            }}
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    pageSize: 10,
                                                    hideOnSinglePage: true,
                                                    position: "both"
                                                }}
                                                dataSource={this.state.List}

                                                renderItem={(item) => (
                                                    <List.Item
                                                        key={item.key}
                                                        actions={[
                                                            <>发布时间: {item.date.replace("T", " ")}</>,
                                                            <>阅读次数: {item.count}</>,
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            title={<a onClick={() => {
                                                                this.content(item.key)
                                                                let kind = item.kind

                                                                let ka = []


                                                                let my = this.V.yuanShiCaiDan.find(e => e.key == kind)
                                                                //console.log(this.V.yuanShiCaiDan,my, kind)
                                                                ka.push(my)
                                                                if(my.key==0){

                                                                }else{
                                                                    let f = this.V.yuanShiCaiDan.find(e => e.key == my.fid)
                                                                    ka.push(f)
                                                                    ka.reverse()
                                                                }
                                                                
                                                                let menuOnClick = { key: kind }
                                                                this.setState({ menuSelectedKey: kind.toString(), Kind: ka, menuOnClick: menuOnClick })



                                                            }}>{item.title}</a>}
                                                            description={this.state.List[0] == "" ? null : this.getSimpleText(item.sammary).substr(0, 70)}
                                                        />
                                                        {this.getSimpleText(item.content).substr(0, 100)}

                                                    </List.Item>
                                                )}
                                            />
                                        )
                                    }

                                })()
                            }




                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
    getSimpleText = (html) => {
        var re1 = new RegExp("<.+?>", "g");
        var html = html.replace(re1, ' ');
        re1 = new RegExp("&nbsp;", "g");
        html = html.replace(re1, ' ');
        re1 = new RegExp("&nbsp", "g");
        html = html.replace(re1, ' ');

        return html;
    }

}